<template>
    <b-col cols="12" class="text-center box p-3" @click="showDetail"
           :style="borrowable.available ? {} : {filter: 'grayscale(100%)', 'background-color': '#f8f8f8'}"
           v-b-tooltip.hover="borrowable.available ? null : 'Emprunté actuellement'">
        <b-row>
            <b-col cols="4" lg="3" xl="2">
                <b-img-lazy :src="thumbnailSrc(borrowable, type)" :alt="borrowable.nom" fluid/>
            </b-col>
            <b-col cols="8" lg="9" xl="10" class="text-left">
                <p class="mb-0"><strong>{{ borrowable.nom }}</strong></p>
                <p class="mb-0 borrowable-description">{{ textDescription }}</p>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
    import {thumbnailSrc}                           from '@/util/image';
    import {loadAndDisplayDetails, textDescription} from '@/util/borrowable';

    export default {
        name: "BorrowableItemRow",
        props: {
            borrowable: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        computed: {
            textDescription() {
                return textDescription(this.borrowable);
            }
        },
        methods: {
            thumbnailSrc,
            showDetail() {
                loadAndDisplayDetails(this.borrowable, this.type)
                    .catch(() => this.$toaster.error('Impossible de charger le détail'));
            }
        }
    }
</script>

<style scoped>
    img.img-fluid {
        filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, .66));
    }

    .box {
        cursor: pointer;
    }

    .box:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    }

    .borrowable-description {
        text-overflow: ellipsis;
        text-align: left;
    }
</style>
